@import url('https://fonts.googleapis.com/css?family=Roboto:400,500');
@import url('https://fonts.googleapis.com/css?family=Lato:700');

html {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
html, body {
  max-width: 100%;
}
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  font-family: 'Roboto', sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a {
  font-size: 14px;
  text-transform: capitalize;
  text-decoration: underline;
  overflow: hidden;
  color: #132e53;
}
a[tabIndex]:focus {
  outline: none;
}
a:hover {
  cursor: pointer;
}

a:disabled {
  color: #b4c6dc;
}
button {
  border: 0;
  padding: 0;
  background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
  box-shadow: 0 0 0px 1000px #FFFFFF inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
a[disabled] {
  pointer-events: none;
  color: #b4c6dc;
}
a[disabled] svg path {
  fill: #b4c6dc;
}
