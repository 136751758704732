.headerContainer {
  height: 70px;
  background: #eee;
  margin-right: -15px;
}
.headerContainerMobile {
  padding: 0 15px;
}
.headerContainer.isOpen {
  position: relative;
}
.headerContainer.isOpen:before {
  content: "";

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(68, 66, 81, 0.65);
}
.contentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.rightSide {
  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}