.logoContainer {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: #245995;
  font-size: 18px;
}
.logoContainer img {
  margin-right: 15px;
}
.userLogoContainer {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #245995;
  display: flex;
  justify-content: center;
  align-items: center;

}
.userLogoContainerMobile {
  height: 30px;
  width: 30px; 
}
.userLogoContainer > span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .26px;
  color: #245995;
  text-transform: uppercase;
}
.userLogoContainer:hover {
  cursor: pointer;
}