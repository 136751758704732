@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
@import url(https://fonts.googleapis.com/css?family=Lato:700);
.Logo_logoContainer___oe6Y {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: #245995;
  font-size: 18px;
}
.Logo_logoContainer___oe6Y img {
  margin-right: 15px;
}
.Logo_userLogoContainer__xYiwx {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #245995;
  display: flex;
  justify-content: center;
  align-items: center;

}
.Logo_userLogoContainerMobile__3lO8E {
  height: 30px;
  width: 30px; 
}
.Logo_userLogoContainer__xYiwx > span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .26px;
  color: #245995;
  text-transform: uppercase;
}
.Logo_userLogoContainer__xYiwx:hover {
  cursor: pointer;
}
.Header_headerContainer__2l1ra {
  height: 70px;
  background: #eee;
  margin-right: -15px;
}
.Header_headerContainerMobile__lLpkX {
  padding: 0 15px;
}
.Header_headerContainer__2l1ra.Header_isOpen__27XbD {
  position: relative;
}
.Header_headerContainer__2l1ra.Header_isOpen__27XbD:before {
  content: "";

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(68, 66, 81, 0.65);
}
.Header_contentContainer__3ObW4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.Header_rightSide__1LAIO {
  flex: 1 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.Button_button__GwoYb {
  height: 46px;
  width: 100%;

  padding: 0;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  
  border: 1px solid transparent;
  border-radius: 10px;
  text-decoration: none;
  text-transform: uppercase;

  -webkit-transition: .3s;

  transition: .3s;
}
.Button_button__GwoYb:hover {
  cursor: pointer;
}
.Button_button__GwoYb:focus {
  outline: none;
}
.Button_button__GwoYb p:focus {
  outline: none;
  color: #f19b2e;
}
.Button_button__GwoYb>svg {
  margin: 0 10px 0 0 !important;
}

.Button_primary__2RMDL {
  color: #ffffff;
  background-color: #245995;
}
.Button_primary__2RMDL:hover {
  background-color: #f19b2e;
}
.Button_primary__2RMDL:active {
  background-color: #1D3796;
}
.Button_primary__2RMDL:disabled {
  color: #b4c6dc;
  background-color: #dfe7f2;
}

.Button_secondary__2-h-d {
  border: 1px solid #b7b3ce;
  color: #454452;
}
.Button_secondary__2-h-d:hover {
  background-color: #FACCA7;
}
.Button_secondary__2-h-d:focus {
  color: #454452;
}
.Button_secondary__2-h-d:active {
  background: #FACCA7;
}
.Button_secondary__2-h-d:disabled {
  color: #b4c6dc;
  background: #dfe7f2;
  border: 1px solid transparent;
}

.Button_link__3EVya {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
}

.Button_confirmation__2ECe7 {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1D3796;
  border: 3px solid #245995;
  border-radius: 90px;
}
.Button_confirmation__2ECe7>svg {
  margin: 0 !important;
}
.Dropdown_dropdownContainer__1MaEn {
  position: relative;
}
.Dropdown_value__aZb-w {
  white-space: nowrap;
  color: #000;
}
.Dropdown_value__aZb-w:hover {
  cursor: pointer;
}
.Dropdown_menu__23SV0 {
  position: absolute;
  top: 0;
  right: 0;
}
.Dropdown_menuContainer__3IVbl {
  position: relative;
  padding: 40px 40px 35px; 
  height: 100%;
  border-top: 3px solid #132e53;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(103, 105, 118, 0.19);
  border-radius: 2px;
}
.Dropdown_menuContainer__3IVbl > svg {
  position: absolute;
  top: -8px;
  left: 0;
}

.HeaderStyles_baseHeaderMobileMenu__3olQE {
  position: fixed;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;

  margin: 70px 0 0;
  padding: 55px 35px 70px;

  list-style: none;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;

  overflow-y: scroll;
}
.HeaderStyles_visibleMenu__26TZ5 {
  top: 0;
}
.HeaderStyles_baseHeaderMobileMenu__3olQE > li {
  margin-bottom: 40px;
}
.HeaderStyles_baseHeaderMobileMenu__3olQE > li > a,
.HeaderStyles_baseHeaderMobileMenu__3olQE > li > div {
  display: block;
  text-decoration: none;
  font-size: 20px;
  text-transform: none;
  text-transform: initial;
  font-weight: normal;
  line-height: 26px;
  color: #454452;
}
.HeaderStyles_baseHeaderMobileMenu__3olQE > li > a:hover,
.HeaderStyles_baseHeaderMobileMenu__3olQE > li > div:hover,
.HeaderStyles_baseHeaderMobileMenu__3olQE > li > a:focus {
  color: #132e53 !important;
}
.HeaderStyles_baseHeaderMobileMenu__3olQE>.HeaderStyles_close__3b1vm {
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 5px;
  margin: 0;
}
.HeaderStyles_baseHeaderMobileMenu__3olQE>.HeaderStyles_close__3b1vm svg path {
  fill: rgb(183, 179, 206);
}
.HeaderStyles_baseHeaderMobileMenu__3olQE .HeaderStyles_separator__1fBzP {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 40px;
}

.HeaderStyles_ButtonLink__14Ezf,
.HeaderStyles_Button__2Be5j {
  float: right;
  margin-left: 35px;
  line-height: 17px;
  letter-spacing: .56px;
  font-size: 14px;
  font-weight: 500;
}
a.HeaderStyles_ButtonLink__14Ezf {
  color: #132e53 !important;
  text-decoration: none;
}
.HeaderStyles_Button__2Be5j {
  height: 100%;
  border-radius: 0;
  border-bottom: 0;
}
.HeaderStyles_messages__2VOP9:hover,
.HeaderStyles_assessmentContainer__2jzxi:hover,
.HeaderStyles_Button__2Be5j:hover,
.HeaderStyles_logoContainer__3MBdg:hover {
  box-shadow: inset 0px -3px 0px 0px #132e53;
}
.HeaderStyles_Button__2Be5j:focus {
  color: #fff;
}
.HeaderStyles_mailIcon__2BoFU {
  margin: 0 20px 0 0;
  position: relative;
}
.HeaderStyles_mailIcon__2BoFU span {
  position: absolute;
  top: -8px;
  right: -8px;
  
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #132e53;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #38364B;
}
.HeaderStyles_userMenu__32gIZ {
  display: flex;
  flex-direction: column;

  top: 45px;
  right: -10px;
  z-index: 2;
  
  width: 275px;
  height: 180px;
}
.HeaderStyles_userMenu__32gIZ .HeaderStyles_separator__1fBzP {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 37px;
}
.HeaderStyles_userMenu__32gIZ a {
  display: block;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  color: #454452;
  text-decoration: none;
  margin-bottom: 31px;
}
.HeaderStyles_userMenu__32gIZ a:hover {
  color: #132e53 !important;
}
.HeaderStyles_count__FldUM {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  background-color: #132e53;
  
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 15px;
  
  font-weight: 500;
  font-size: 9px;
  line-height: normal;
  color: #000;
}

.HeaderStyles_userMenu__32gIZ a:last-child {
  margin-bottom: 0;
}
.HeaderStyles_userMenu__32gIZ a:hover,
.HeaderStyles_userMenu__32gIZ a:focus {
  color: #132e53;
}
.HeaderStyles_userMenu__32gIZ>.HeaderStyles_menuContainer__p5uCO {
  height: 100px;
}
.HeaderStyles_mailLink__3-fCR {
  height: 100%;
  display: flex;
  align-items: center;
}
.HeaderStyles_logoContainer__3MBdg {
  height: 100%;
  display: flex;
  align-items: center;
  -webkit-transition: all .3s;
  transition: all .3s;
  margin-left: 16px;
}
.HeaderStyles_assessmentContainer__2jzxi {
  height: 100%;
  display: flex;
  align-items: center;
  -webkit-transition: all .3s;
  transition: all .3s;
  margin-left: 35px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.HeaderStyles_assessmentContainer__2jzxi>a {
  text-decoration: none;
  color: #fff;
}
.HeaderStyles_assessmentContainer__2jzxi .HeaderStyles_userMenu__32gIZ {
  height: auto;
  top: 35px;
}
.HeaderStyles_messages__2VOP9 {
  margin-left: 35px;
  margin-right: 20px;
  
  position: relative;
  text-decoration: none;
  
  font-size: 14px;
  font-weight: 500;
  color: #fff;

  height: 100%;
  display: flex;
  align-items: center;
}
.HeaderStyles_withCount__2rWwK {
  padding-right: 20px;
  margin-right: 0;
}
.HeaderStyles_messages__2VOP9:focus {
  color: #fff;
}
.HeaderStyles_counterContainer__2XU_V {
  position: relative;
}
.HeaderStyles_counterContainer__2XU_V>div {
  position: absolute;
  right: -20px;
  top: 0;
  
  width: 15px;
  height: 15px;
  
  background-color: #132e53;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 15px;
  
  font-weight: 500;
  font-size: 9px;
  line-height: normal;
  color: #000;
}

.HeaderStyles_nameContainer__3t2yO {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
}
.HeaderStyles_nameContainer__3t2yO>span {
  flex: 1 1;
  margin-left: 15px;
}
.HeaderStyles_ButtonMail__hwqoT {
  height: 46px;
  color: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  border: 1px solid transparent;
  border-radius: 30px;
  text-decoration: none;
  -webkit-transition: .3s;
  transition: .3s;
  height: 100%;
  border-radius: 0;
  border-bottom: 0;
  text-transform: none;
  text-transform: initial;
}
.Modal_modal__lA7fU {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  width:100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.6) !important;

  display: none;
  text-align: center;
}

.Modal_modalMain__Xock6 {
  position:fixed;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);

  background: white;

  width: 100%;
  height: auto;
  width: 290px;
  max-height: 293px;

  padding: 46px 36px 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Modal_modalMain__Xock6 h3 {
  margin: 0 -5px 11px;
  color: #454452;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
}

.Modal_modalMain__Xock6>div {
  width: 100%;
  flex: 1 1;
}
.Modal_modalMain__Xock6 div>button {
  max-width: 136px;
  margin: 0 auto 17px;
}

.Modal_close__1WC7R {
  position: absolute;
  top: 0px;
  right: 0px;

  padding: 20px;
}
.Modal_close__1WC7R:hover {
  cursor: pointer;
}
.Modal_show__3XL60 {
  display: block;
}


.TextBox_input__2Kuvs{
  width: 100%;
}
.TextBox_input__2Kuvs label {
  color: #858297 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
.TextBox_error__1CQjp {
  position: absolute;
  top: 65px;
  margin-top: 0 !important;
}
.TextBox_errorTextArea__1zqmT {
  position: absolute;
  bottom: -20px;
  margin-top: 0 !important;
}
.TextBox_input__2Kuvs label[data-shrink="true"] {
  font-size: 14px;
  -webkit-transform: translate(0, 1.5px) scale(1);
          transform: translate(0, 1.5px) scale(1);
}
.TextBox_input__2Kuvs div[type="text"] {
  display: flex;
  padding: 0;
}
.TextBox_input__2Kuvs div[type="text"] > div {
  padding: 0;
  font-weight: 500;
  height: 43px;
}

.TextBox_input__2Kuvs>div:before,
.TextBox_input__2Kuvs>div:hover:before {
  border-bottom: 1px solid #b7b3ce !important;
}
.TextBox_input__2Kuvs>div:after {
  border-bottom: 1px solid #132e53;
}
.TextBox_input__2Kuvs input {
  padding: 14px 0 10px;
  color: #454452;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.04em;
}
.TextBox_input__2Kuvs textarea {
  min-height: 30px;
  color: #454452;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.04em;
}
.TextBox_visibilityIcon__1AUvz {
  color: #132e53;
}
.TextBox_password__18hNE {
  position: relative;
}
.TextBox_toggleVisibility__11sW2 {
  position: absolute;
  right: 0;
  top: 29px;
  
  padding: 0;
  
  width: 25px;
  height: 20px;
}
.TextBox_toggleVisibility__11sW2>svg {
  width: 25px;
  height: 20px;
}
.TextBox_toggleVisibility__11sW2:hover {
  cursor: pointer;
}
.TextBox_password__18hNE button {
  background-color: transparent;
}
.TextBox_password__18hNE input {
  padding-right: 25px;
}
.UserContainer_userContainer__1CA-c {
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  flex: 1 1;
  max-width: none !important;
  max-width: initial !important;
  width: 100%;
  overflow-y: hidden;
}
.UserContainer_titleInfo__2rF1a {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #454452;
}
.UserContainer_addRoutingPlan__1apg8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  max-width: 320px;
}
.UserContainer_routeContainer__1wM5q {
  width: 100%;
}
.UserContainer_routeContainerMobile__2geXM {
  flex: 1 1;
}
.UserContainer_addRoutingPlan__1apg8 > div {
  margin: 0 0 10px 0;
}
.UserContainer_Button__2v4hZ {
  max-width: 130px;
}
.UserContainer_ButtonAdd__OKapC {
  max-width: 130px;
  margin-bottom: 20px;
}
.UserContainer_wrapper__2P4fb {
  overflow-x: visible;
  padding-bottom: 10px;
}
.UserContainer_table__o_hZo {
  display: flex;
  flex-direction: column;
}  
.UserContainer_table__o_hZo .UserContainer_header__l62Oq,
.UserContainer_table__o_hZo .UserContainer_row__2Zj0F {
  display: flex;
}
.UserContainer_price__119P3 {
  cursor: pointer;
  text-decoration: underline;
  color: #245995;
}
.UserContainer_row__2Zj0F {
  font-size: 14px;
}
.UserContainer_table__o_hZo .UserContainer_header__l62Oq div,
.UserContainer_table__o_hZo .UserContainer_row__2Zj0F>div {
  flex: 1 1;
  padding: 5px 10px;
  border: 1px solid #e4e4e4;
  min-width: 120px;
  overflow: hidden;
}
.UserContainer_history__2rqq1 .UserContainer_table__o_hZo .UserContainer_header__l62Oq div:last-child,
.UserContainer_history__2rqq1 .UserContainer_table__o_hZo .UserContainer_row__2Zj0F>div:last-child {
  min-width: 250px;
}
.UserContainer_routeContainerMobile__2geXM .UserContainer_table__o_hZo .UserContainer_header__l62Oq div,
.UserContainer_routeContainerMobile__2geXM .UserContainer_table__o_hZo .UserContainer_row__2Zj0F>div {
  min-width: 110px;
}
.UserContainer_table__o_hZo .UserContainer_header__l62Oq div {
  background-color: rgb(238, 238, 238);
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  color: #245995;
}
.UserContainer_table__o_hZo .UserContainer_header__l62Oq div>svg { 
  min-width: 20px;
}

.UserContainer_modalView__3SA6D {
  width: 360px;
}
.UserContainer_modalView__3SA6D > div {
  display: inline-flex;
}
.UserContainer_modalView__3SA6D .UserContainer_modalButton__2yeyO {
  margin: 20px auto 0;
}
.UserContainer_routeContainerMobile__2geXM .UserContainer_modalView__3SA6D {
  width: 290px;
}
.UserContainer_routeContainerMobile__2geXM .UserContainer_modalView__3SA6D > div {
  flex-direction: column;
}
.UserContainer_modalAdd__1pjkh {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  width: 320px;
  padding-bottom: 10px;
}
.UserContainer_modalAdd__1pjkh>div {
  width: 100%;
}
.UserContainer_DropdownContainer__12cci {
  width: 100%;
}
.UserContainer_DropdownContainer__12cci>div {
  padding-bottom: 20px;
}
.UserContainer_DropdownContainer__12cci>div>p {
  position: absolute;
  bottom: 0;
}
.UserContainer_navigation__1XyEQ {
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
}
.UserContainer_link__2hm-w {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
  text-decoration: none;
  margin-right: 15px;
}
.UserContainer_selected__2q_Xk {
  color: #245995;
  text-decoration: underline;
}

.UserContainer_modalForm__bx7cY {
  width: 100%;
}

.UserContainer_error__3m1WK {
  margin: 0 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #f44336;
}

.UserContainer_loading__3jx_J {
  margin: 0 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #b1afba;
}

.UserContainer_pagination__3CDjZ {
  display: inline-flex;
  width: 100%;
  margin-top: 10px;
  padding: 0;
}
.UserContainer_pagination__3CDjZ>*{
  list-style-type: none;
  line-height: 23.4px;
}
.UserContainer_pagination__3CDjZ>* a {
  text-decoration: none;
  font-size: 18px;
}
.UserContainer_page__yEK8O,
.UserContainer_break__3E87L {
  margin-right: 10px;
}
.UserContainer_page__yEK8O>a {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
} 
.UserContainer_active__3JOE6 {
  text-decoration: underline;
}
.UserContainer_active__3JOE6>a {
  color: #245995;
  font-weight: bold;
}
.UserContainer_prev__1w7GN {
  margin-right: 20px;
}
.UserContainer_next__VO12c {
  margin-left: 10px;
}

.UserContainer_container__1GjnJ {
  display: inline-flex;
  justify-content: center;
  width: 100%;
}
.UserContainer_container__1GjnJ button {
  flex: 1 1;
}

/* Search Form styles */
.UserContainer_searchForm__2ogm4 {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
}
.UserContainer_searchForm__2ogm4 > div {
  max-width: 250px;
  margin-right: 30px;
}

.UserContainer_searchButton__3B38i {
  max-width: 100px;
  margin-right: 20px;
}
.UserContainer_searchForm__2ogm4 > .UserContainer_DropdownContainer__12cci > div {
  padding-bottom: 0;
}
.HomePage_homeContainer__1my_S {
  display: flex;
  flex-direction: column;

  flex: 1 1;
  max-width: 798px;
  margin: auto;
}

.BaseContainer_baseContainer__gRXMA {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-right: 15px;
}

.Loading_wrapper__1UiRz {
  position: relative;
  width: 100%;
  flex: 1 1;
}

.Loading_loader__EiCRi {
	height: 32px;
  width: 32px;
  display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
}

.Loading_loader__EiCRi::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 12px;
	height: 12px;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	background: #132e53;
	border-radius: 50%;
  -webkit-animation: Loading_loader-4-1__2YYsb 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-1__2YYsb 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-1__2YYsb {
	0%   { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
	50%  { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
	100% { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
}
@keyframes Loading_loader-4-1__2YYsb {
	0%   { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
	50%  { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
	100% { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
}
.Loading_loader__EiCRi span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
  -webkit-animation: Loading_loader-4-2__2c6eU 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-2__2c6eU 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-2__2c6eU {
	0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	50%  { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes Loading_loader-4-2__2c6eU {
	0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	50%  { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.Loading_loader__EiCRi span::before,
.Loading_loader__EiCRi span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 12px;
	width: 12px;
	background: #132e53;
	border-radius: 50%;
  -webkit-animation: Loading_loader-4-3__1osyy 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-3__1osyy 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-3__1osyy {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-16px, 0, 0) scale(.5); transform: translate3d(-16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes Loading_loader-4-3__1osyy {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-16px, 0, 0) scale(.5); transform: translate3d(-16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
.Loading_loader__EiCRi span::after {
  -webkit-animation: Loading_loader-4-4__CJHUc 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-4__CJHUc 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-4__CJHUc {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(16px, 0, 0) scale(.5); transform: translate3d(16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes Loading_loader-4-4__CJHUc {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(16px, 0, 0) scale(.5); transform: translate3d(16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}

.LoginPage_loginPage__2OVUD {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 40px 0;
  min-height: calc(100vh - 80px);
}
.LoginPage_resend__3RvNk {
  background: #eafff6;
}
.LoginPage_loginPageMobile__D_h-3 {
  padding: 30px 0;
}
.LoginPage_LoginScreenTitle__30AMg {
  color: #132e53;
  font-size: 26px;
  font-weight: 400;
  line-height: 31px;
  margin: 30px 0;
}
.LoginPage_LoginScreenTitleMobile__2f4ah {
  font-size: 24px;
  line-height: 29px;
}
.LoginPage_LoginScreenContainer__fV5E_ > p {
  font-size: 14px;
  color: #454452;
  margin: 20px 0;
}
.LoginPage_button__28qUK {
  margin-top: 12px;
}
/*Welcome screen*/
.LoginPage_loginPageContent__9Um4g {
  height: 531px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.LoginPage_title__2bQOq {
  color: #b1afba;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  margin: 0;
}
.LoginPage_loginPage__2OVUD .LoginPage_footer__3zkyZ {
  color: #5c5b66;
  font-size: 14px;
  margin: 0;
}
.LoginPage_loginPage__2OVUD .LoginPage_modalFooter__nVdrh {
  margin: 45px 0 25px;
  font-weight: 500;
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #454452;
}
.LoginPage_modalButton__S1xUW {
  margin-bottom: 0 !important;
}
/*Login Form*/
.LoginPage_loginForm__163VI {
  width: 240px;
  margin-top: 51px;
  flex: 1 1;
}
.LoginPage_loginFormMobile__WXnGe {
  margin-top: 33px;
}
.LoginPage_formItems__1MeXW>div {
  margin-bottom: 36px;
}
.LoginPage_formItems__1MeXW>div:last-child {
  margin-bottom: 2px;
}

.LoginPage_submitButton__1kMnj {
  max-width: 136px;
  margin: 24px auto 0;
}

.LoginPage_loginFooter__btBkB {
  margin: 18px auto 0;
  color: #5c5b66;
  font-size: 14px;
}
.LoginPage_checkBoxContainer__q0rZN {
  margin-top: 14px;
  font-size: 14px;
  text-align: start;
  color: #5c5b66;
}
.LoginPage_checkBoxLabel__6oOCy {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .56px;
  color: #5c5b66;
  margin-top: 16px;
  margin-left: -19px;
  text-transform: capitalize;
}
.LoginPage_errorContainer__3NmH7 {
  position: relative;
}
.LoginPage_loginError__2RKxC {
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 8px;
  line-height: 1em;
  position: absolute;
  top: -15px;
  text-align: left;
}
.LoginPage_LoginScreenContainer__fV5E_ {
  width: 280px;
  flex: 1 1;
  margin-top: 30px;
}



.LoginPage_verificationContainer__2lYS6 {
  margin-top: 57px;
  flex: 1 1;
}
.LoginPage_verificationContainerMobile__2R1Ii {
  margin-top: 30px;
}
.LoginPage_verificationContainer__2lYS6 h1 {
  margin: 0 0 37px;
  color: #132e53;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 1.14px;
  text-transform: capitalize;
}
.LoginPage_verificationContainerMobile__2R1Ii h1 {
  margin: 0 0 24px;
  font-size: 32px;
  letter-spacing: .96px;
}
.LoginPage_verificationContainer__2lYS6 h3 {
  margin: 0 auto 48px;
  color: #5c5b66;
  font-size: 26px;
  font-weight: 400;
  line-height: 32.6px;
  max-width: 570px;
}
.LoginPage_verificationContainerMobile__2R1Ii h3 {
  margin: 0 auto 62px;
  font-size: 24px;
  line-height: 31.5px;
  max-width: 259px;
}
.LoginPage_verificationContainer__2lYS6>svg {
  margin-left: -40px;
}
.LoginPage_verificationContainer__2lYS6 p {
  margin: 0 auto;
  color: #5c5b66;
  font-size: 14px;
  line-height: 20.5px;
  max-width: 209px;
}
p.LoginPage_verificationDescription__1blo- {
  height: 53px;
  margin: 40px auto 42px;
  color: #454452;
  font-size: 20px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: 400;
  line-height: 26px;
}
.LoginPage_verificationContainerMobile__2R1Ii p.LoginPage_verificationDescription__1blo- {
  margin: 42px auto 63px;
  color: #454452;
  font-size: 18px;
  line-height: 23.6px;
  max-width: 220px;
}

.ForgotPage_forgotPage__ElLsa {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  text-align: center;

  flex-direction: column;
  padding: 40px 0;
  min-height: calc(100vh - 80px);
}

.ForgotPage_forgot__3o9wH {
  background: #eafff6;
}
.ForgotPage_forgotPageMobile__VMuOt {
  padding-top: 30px;
}

.ForgotPage_forgotPage__ElLsa > p {
  color: #b1afba;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .56px;
  margin: 0;
}

.ForgotPage_forgotForm__QX1_Y {
  margin-top: 51px;
  width: 240px;
}
.ForgotPage_submitButton__33Glh {
  max-width: 176px;
  margin: 36px auto 0;
}
.ForgotPage_forgotError__2KoS3 {
  color: #f44336;
  font-size: 0.75rem;
  margin: 8px auto;
  line-height: 1em;
  text-align: left;
}

.ForgotPage_forgotContainer__2o1gO {
  margin-top: 57px;
}
.ForgotPage_forgotContainerMobile__hAx8U {
  margin-top: 38px;
}
.ForgotPage_forgotContainer__2o1gO h1 {
  margin: 0 0 27px;
  color: #132e53;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 1.14px;
  text-transform: capitalize;
}
.ForgotPage_forgotContainerMobile__hAx8U h1 {
  margin: 0 0 24px;
  font-size: 32px;
  letter-spacing: .96px;
}
.ForgotPage_forgotContainer__2o1gO h3 {
  margin: 0 0 46px;
  color: #5c5b66;
  font-size: 26px;
  font-weight: 400;
  line-height: 32.6px;
}
.ForgotPage_forgotContainerMobile__hAx8U h3 {
  margin: 0 auto 26px;
  font-size: 24px;
  line-height: 31.5px;
  max-width: 259px;
}
.ForgotPage_forgotContainer__2o1gO>svg {
  margin-left: -40px;
}
.ForgotPage_forgotContainer__2o1gO p {
  margin: 0 auto;
  color: #5c5b66;
  font-size: 14px;
  line-height: 20.5px;
  max-width: 260px;
}
.ForgotPage_forgotContainer__2o1gO .ForgotPage_Button__1CWdR {
  margin: 44px auto 90px;
  max-width: 240px;
}
.ForgotPage_forgotContainerMobile__hAx8U .ForgotPage_Button__1CWdR {
  margin: 42px auto 59px;
}
.ForgotPage_forgotContainer__2o1gO .ForgotPage_Button__1CWdR p {
  color: #fff;
}
.AccountContainer_accountContainer__2OHq1 {
  min-height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.AccountContainer_accountContainer__2OHq1 header {
  margin-right: 0;
  width: 100%;
}
html {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
html, body {
  max-width: 100%;
}
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  font-family: 'Roboto', sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a {
  font-size: 14px;
  text-transform: capitalize;
  text-decoration: underline;
  overflow: hidden;
  color: #132e53;
}
a[tabIndex]:focus {
  outline: none;
}
a:hover {
  cursor: pointer;
}

a:disabled {
  color: #b4c6dc;
}
button {
  border: 0;
  padding: 0;
  background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important;
  box-shadow: 0 0 0px 1000px #FFFFFF inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
a[disabled] {
  pointer-events: none;
  color: #b4c6dc;
}
a[disabled] svg path {
  fill: #b4c6dc;
}

