.accountContainer {
  min-height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.accountContainer header {
  margin-right: 0;
  width: 100%;
}