.userContainer {
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  flex: 1;
  max-width: initial !important;
  width: 100%;
  overflow-y: hidden;
}
.titleInfo {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #454452;
}
.addRoutingPlan {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  max-width: 320px;
}
.routeContainer {
  width: 100%;
}
.routeContainerMobile {
  flex: 1;
}
.addRoutingPlan > div {
  margin: 0 0 10px 0;
}
.Button {
  max-width: 130px;
}
.ButtonAdd {
  max-width: 130px;
  margin-bottom: 20px;
}
.wrapper {
  overflow-x: visible;
  padding-bottom: 10px;
}
.table {
  display: flex;
  flex-direction: column;
}  
.table .header,
.table .row {
  display: flex;
}
.price {
  cursor: pointer;
  text-decoration: underline;
  color: #245995;
}
.row {
  font-size: 14px;
}
.table .header div,
.table .row>div {
  flex: 1;
  padding: 5px 10px;
  border: 1px solid #e4e4e4;
  min-width: 120px;
  overflow: hidden;
}
.history .table .header div:last-child,
.history .table .row>div:last-child {
  min-width: 250px;
}
.routeContainerMobile .table .header div,
.routeContainerMobile .table .row>div {
  min-width: 110px;
}
.table .header div {
  background-color: rgb(238, 238, 238);
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  color: #245995;
}
.table .header div>svg { 
  min-width: 20px;
}

.modalView {
  width: 360px;
}
.modalView > div {
  display: inline-flex;
}
.modalView .modalButton {
  margin: 20px auto 0;
}
.routeContainerMobile .modalView {
  width: 290px;
}
.routeContainerMobile .modalView > div {
  flex-direction: column;
}
.modalAdd {
  max-height: fit-content;
  width: 320px;
  padding-bottom: 10px;
}
.modalAdd>div {
  width: 100%;
}
.DropdownContainer {
  width: 100%;
}
.DropdownContainer>div {
  padding-bottom: 20px;
}
.DropdownContainer>div>p {
  position: absolute;
  bottom: 0;
}
.navigation {
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
}
.link {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
  text-decoration: none;
  margin-right: 15px;
}
.selected {
  color: #245995;
  text-decoration: underline;
}

.modalForm {
  width: 100%;
}

.error {
  margin: 0 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #f44336;
}

.loading {
  margin: 0 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #b1afba;
}

.pagination {
  display: inline-flex;
  width: 100%;
  margin-top: 10px;
  padding: 0;
}
.pagination>*{
  list-style-type: none;
  line-height: 23.4px;
}
.pagination>* a {
  text-decoration: none;
  font-size: 18px;
}
.page,
.break {
  margin-right: 10px;
}
.page>a {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
} 
.active {
  text-decoration: underline;
}
.active>a {
  color: #245995;
  font-weight: bold;
}
.prev {
  margin-right: 20px;
}
.next {
  margin-left: 10px;
}

.container {
  display: inline-flex;
  justify-content: center;
  width: 100%;
}
.container button {
  flex: 1;
}

/* Search Form styles */
.searchForm {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
}
.searchForm > div {
  max-width: 250px;
  margin-right: 30px;
}

.searchButton {
  max-width: 100px;
  margin-right: 20px;
}
.searchForm > .DropdownContainer > div {
  padding-bottom: 0;
}